import sysEnv from '@/env/env'
import { createWorkbook, bookAppendSheet, aoaToSheet, downloadExcelWithStyle, setWorkbookBaseStyle, setWorksheetCellStyle, Colspan, colspan } from '@/components/excel/lib'
import { fileRequest } from '@/api'
import { loadResourcesByCache } from '@/components/base/file/libv2'

const titleName = sysEnv.title || '未配置单位名称'
console.log(titleName, sysEnv)

function getWorkSheet (data, title, records) {
  const purchaseBatchDetailList = data.purchaseBatchDetailList || []
  
  const items = purchaseBatchDetailList.map((item, i) => {
    return [
      ...(purchaseBatchDetailList.length > 1 ? [
        { data: ['项目' + (i + 1), ...colspan(5)], merges: [[0, 5]], rows: { hpx: 45 } }
      ] : []),
      {
        data: ['项目名称', item.goodsName, ...colspan(4)], merges: [[1, 5]], rows: { hpx: 45 }
      },
      {
        data: [
          '申报部门', item.orgName,
          '申报人', item.userName,
          '申报时间', item.createTime.substr(0, 10)
        ],
        rows: { hpx: 45 }
      },
      {
        data: [
          '单价', item.price ? (item.price + '元') : '',
          '数量', item.num || '',
          '总价', item.total ? (item.total + '元') : ''
        ],
        rows: { hpx: 45 }
      },
    ]
  })

  const aoa = [
    {
      data: [title, ...colspan(5)], merges: [[0, 5]], rows: { hpx: 75 }
    },
    {
      data: [
        '职能科室', data.busOrgName,
        '申请人', data.userName,
        '申请时间', data.createTime.substr(0, 10)
      ],
      rows: { hpx: 45 }
    },
    {
      data: [
        '类型', data.typeLabel, ...colspan(1),
        '采购方式', data.method, ...colspan(1)
      ],
      merges: [[1, 2], [4, 5]],
      rows: { hpx: 45 }
    },
    ...items.flat(),
    ...records
  ]
  
  const worksheet = aoaToSheet(aoa.map(v => v.data))
  worksheet['!rows'] = {}

  const merges = []
  aoa.forEach((v, i) => {
    if (v.merges && Array.isArray(v.merges) && v.merges.length) {
      merges.push(...new Colspan(v.merges, i))
    }
    if (v.rows) {
      worksheet['!rows'][i] = v.rows
    }
  })
  worksheet['!merges'] = merges
  worksheet['!cols'] = [{ wpx: 100 }, { wpx: 120 }, { wpx: 82 }, { wpx: 90 }, { wpx: 70 }, { wpx: 102 }]

  return worksheet
}

function getMini (data) {
  const base = data.statusActionRecord.map(({ id, statusKey, remark, userName, createTime }) => {
    return { id, statusKey, remark, userName, createTime }
  }).sort((a, b) => a.id - b.id)

  const records = [
    { key: 'draw', label: '职能科室意见' },
    { key: 'wait_6', label: '分管领导意见' },
    { key: 'wait_7', label: '院长意见' }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    return {
      data: [item.label, '', res ? ([res.remark, '(', res.userName, res.createTime.substr(0, 10), ')']).join(' ') : '', '', '', ''],
      merges: [[0, 1], [2, 5]],
      rows: { hpx: 135 }
    }
  })

  return getWorkSheet(data, titleName + '物品、设备采购（维保）方式申请单', records)
}

function getSmall (data) {
  const base = data.statusActionRecord.map(({ id, statusKey, remark, userName, createTime }) => {
    return { id, statusKey, remark, userName, createTime }
  }).sort((a, b) => a.id - b.id)

  const records = [
    { key: 'draw', label: '职能科室意见' },
    { key: 'wait_6', label: '分管领导意见' },
    { key: 'wait_3', label: '办公会意见' }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    return {
      data: [item.label, '', res ? ([res.remark, '(', res.userName, res.createTime.substr(0, 10), ')']).join(' ') : '', '', '', ''],
      merges: [[0, 1], [2, 5]],
      rows: { hpx: 135 }
    }
  })

  return getWorkSheet(data, titleName + '物品、设备采购（维保）方式申请单', records)
}

function getBig (data) {
  const base = data.statusActionRecord.map(({ id, statusKey, remark, userName, createTime }) => {
    return { id, statusKey, remark, userName, createTime }
  }).sort((a, b) => a.id - b.id)

  const records = [
    { key: 'draw', label: '职能科室意见' },
    { key: 'wait_1', label: '科室提报参数' },
    { key: 'wait_2', label: '分管领导意见' },
    { key: 'wait_3', label: '办公会意见' }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    return {
      data: [item.label, '', res ? ([res.remark, '(', res.userName, res.createTime.substr(0, 10), ')']).join(' ') : '', '', '', ''],
      merges: [[0, 1], [2, 5]],
      rows: { hpx: 135 }
    }
  })

  return getWorkSheet(data, titleName + '物品、设备采购（维保）方式申请单', records)
}

function getParam (data) {
  const base = data.statusActionRecord.map(({ id, statusKey, remark, userName, createTime }) => {
    return { id, statusKey, remark, userName, createTime }
  }).sort((a, b) => a.id - b.id)

  const records = [
    { key: 'wait_5', label: '职能科室上传参数' },
    { key: 'wait_6', label: '分管领导意见' },
    { key: 'wait_7', label: '院长意见' }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    return {
      data: [item.label, '', res ? ([res.remark, '(', res.userName, res.createTime.substr(0, 10), ')']).join(' ') : '', '', '', ''],
      merges: [[0, 1], [2, 5]],
      rows: { hpx: 135 }
    }
  })

  return getWorkSheet(data, titleName + '物品、设备采购方式参数申请单', records)
}

function setWorksheetTitle (worksheet) {
  const emptyBorder = {
    top: { style: 'thin', color: { rgb: 'FFFFFF' } },
    bottom: { style: 'thin', color: { rgb: 'FFFFFF' } },
    left: { style: 'thin', color: { rgb: 'FFFFFF' } },
    right: { style: 'thin', color: { rgb: 'FFFFFF' } }
  }
  // 表头
  setWorksheetCellStyle(worksheet, [
    ...new Array(6).fill(null).map((v, i) => [0, i]),
  ], {
    font: {
      bold: true,
      name: '宋体',
      sz: 18
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true
    },
    border: emptyBorder
  })
}

function exportPdf (html) {
  const id = new Date().getTime()+ '-' + parseInt(Math.random() * 100)
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', id)
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  const style = '<link rel="stylesheet" href="/static/document/table-print.css">'
  const print = '<script>document.addEventListener("DOMContentLoaded", function () {setTimeout(function () {window.print();setTimeout(function () {window.parent.document.getElementById("' + id + '").remove();}, 1000)}, 0);});</script>'
  iframe.srcdoc = '<!DOCTYPE html><head>' + style + '</head><body>' + html + '</body> ' + print + ' </html>'
}

async function getTableData (data, title, recordKeys) {
  const purchaseBatchDetailList = data.purchaseBatchDetailList || []
  const items = purchaseBatchDetailList.map((item, i) => {
    return `
      <tr class="border" style="line-height: 2;${purchaseBatchDetailList.length > 1 ? '' : 'display: none;'}">
        <td colspan="6">项目${i + 1}</td>
      </tr>
      <tr class="border" style="line-height: 2">
        <td>项目名称</td>
        <td colspan="5" style="text-align: left">${item.goodsName || ''}</td>
      </tr>
      <tr class="border" style="line-height: 2">
        <td>申报部门</td>
        <td>${item.orgName || ''}</td>
        <td>申报人</td>
        <td>${item.userName || ''}</td>
        <td>申报时间</td>
        <td>${item.createTime.substr(0, 10)}</td>
      </tr>
      <tr class="border" style="line-height: 2">
        <td>单价</td>
        <td>${item.price ? (item.price + '元') : ''}</td>
        <td>数量</td>
        <td>${item.num || ''}</td>
        <td>总价</td>
        <td>${item.total ? (item.total + '元') : ''}</td>
      </tr>
    `
  }).join('')

  const base = data.statusActionRecord.map(({ id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }) => {
    return { id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }
  }).sort((a, b) => a.id - b.id)

  const signFileIds = Array.from(new Set([
    ...base.map(v => v.userSignFileId),
    data.userSignFileId
  ])).filter(v => v)
  const signFileData = signFileIds.length ? await fileRequest.getFileData({
    ids: signFileIds.join(',')
  }) : []
  const signFiles = await Promise.all(signFileData.map(async file => {
    const blob = await loadResourcesByCache({ file })
    return { id: file.id, blob }
  }))

  const records = recordKeys.map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    const sign = res ? signFiles.find(v => v.id === res.userSignFileId) : null
    return `
      <tr class="border">
        <td style="height: 90px" colspan="2">${item.label}</td>
        <td style="height: 90px" colspan="4">
          ${res ? (res.remark || res.statusActionLabel) : ''}<br />
          <div style="margin-top: 20px;justify-content: flex-end;display: flex;align-items: center;">
            签字：
            <span style="display: ${sign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
            <img style="display: ${sign ? 'inline-block' : 'none'};height: 1cm;" src="${sign ? sign.blob : ''}" />
            <span style="display: inline-block;min-width: 3cm;">
              ${res ? res.createTime.substr(0, 10) : ''}
            </span>
          </div>
        </td>
      </tr>
    `
  }).join('')

  return `
    <table class="single" style="font-size: 12px">
      <tr style="border-bottom: 1px solid #000;">
        <td colspan="6" style="font-weight: bolder;font-size: 18px;line-height: 75px;">${title}</td>
      </tr>
      <tr class="border" style="line-height: 2;">
        <td>职能科室</td>
        <td>${data.busOrgName || ''}</td>
        <td>申请人</td>
        <td>${data.userName || ''}</td>
        <td>申请时间</td>
        <td>${data.createTime.substr(0, 10)}</td>
      </tr>
      <tr class="border" style="line-height: 2">
        <td>类型</td>
        <td colspan="2">${data.typeLabel || ''}</td>
        <td>采购方式</td>
        <td colspan="2">${data.method || ''}</td>
      </tr>
      ${items}
      ${records}
    </table>
  `
}

async function exportMiniPdf (data) {
  const html = await getTableData(data, titleName + '物品、设备采购方式申请单', [
    { key: 'draw', label: '职能科室意见' },
    { key: 'wait_6', label: '分管领导意见' },
    { key: 'wait_7', label: '院长意见' }
  ])
  exportPdf(html)
}

async function exportSmallPdf (data) {
  const html = await getTableData(data, titleName + '物品、设备采购方式申请单', [
    { key: 'draw', label: '职能科室意见' },
    { key: 'wait_6', label: '分管领导意见' },
    { key: 'wait_3', label: '办公会意见' }
  ])
  exportPdf(html)
}

function exportBigPdf (data) {
  return getTableData(data, titleName + '物品、设备采购方式申请单', [
    { key: 'draw', label: '职能科室意见' },
    { key: 'wait_1', label: '科室提报参数' },
    { key: 'wait_2', label: '分管领导意见' },
    { key: 'wait_3', label: '办公会意见' }
  ])
}

function exportParamPdf (data) {
  return getTableData(data, titleName + '物品、设备采购方式参数申请单', [
    { key: 'wait_5', label: '职能科室上传参数' },
    { key: 'wait_6', label: '分管领导意见' },
    { key: 'wait_7', label: '院长意见' }
  ])
}

export async function defaultPurchaseMethod (data, type = 'pdf') {
  const workbook = createWorkbook()

  if (data.type === 'mini') {
    data.typeLabel = '2000以下'
    if (type === 'pdf') {
      return exportMiniPdf(data)
    }
    bookAppendSheet(workbook, getMini(data), '2000以下')
  } else if (data.type === 'small') {
    data.typeLabel = '2000以上'
    if (type === 'pdf') {
      return exportSmallPdf(data)
    }
    bookAppendSheet(workbook, getSmall(data), '2000以上')
  } else if (data.type === 'big') {
    data.typeLabel = '大型采购'
    if (type === 'pdf') {
      const bigHtml = await exportBigPdf(data)
      const paramHtml = await exportParamPdf(data)
      return exportPdf(bigHtml + '<div style="page-break-after: always;"></div>' + paramHtml)
    }
    bookAppendSheet(workbook, getBig(data), '大型采购')
    bookAppendSheet(workbook, getParam(data), '大型采购参数')
  } else {
    return
  }

  const blackBorder = {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } }
  }

  // 设置表格基础样式
  setWorkbookBaseStyle(workbook, {
    font: {
      name: '宋体',
      sz: 12
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true
    },
    border: blackBorder
  })

  workbook.SheetNames.forEach(sheetName => {
    setWorksheetTitle(workbook.Sheets[sheetName])
  })

  downloadExcelWithStyle(workbook, [data.year, data.busOrgName, (data.purchaseBatchDetailList || []).map(v => v.goodsName).join('、')].join('-'))
}

export const detailFilter = (dataItem, parm) => {
  let pass = true
  if (!parm) {
    return pass
  }
  let keys = Object.keys(parm).filter(v => v != '__key')
  let i = 0
  while (pass && i < keys.length) {
    let key = keys[i]
    pass = dataItem[key] === parm[key]
    i += 1
  }
  return pass
}


export const getBatchInfo = (datas) => {
  if (!datas || datas.length === 0) {
    return null
  }
  let info = {
    startTime: null,
    endTime: null,
    cyOrgNum: 0,
    cyLeaderNum: 0,
    isEnd: true,
    fileIds: []
  }
  let cyOrgs = []
  let cyLeaders = []
  let i = 0
  while (i < datas.length) {
    let item = datas[i]
    info.isEnd = info.isEnd && item.status === 'end'
    if (item.fileIds) {
      info.fileIds.push(item.fileIds)
    }

    let submitOrgIds = item.submitOrgIds ? item.submitOrgIds.split(',') : []
    submitOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)

    let busOrgIds = item.busOrgIds ? item.busOrgIds.split(',') : []
    busOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)

    let leadUserIds = item.leadUserIds ? item.leadUserIds.split(',') : []
    leadUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)
    
    let deanUserIds = item.deanUserIds ? item.deanUserIds.split(',') : []
    deanUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)

    let purchaseMethodLeadUserIds = item.purchaseMethodLeadUserIds ? item.purchaseMethodLeadUserIds.split(',') : []
    purchaseMethodLeadUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)

    if (item.statusActionRecord && item.statusActionRecord.length > 0) {
      let start = item.statusActionRecord.find(v => v.statusActionKey === 'creating_switch_doing' && !v.isBack)
      if (start) {
        if (info.startTime == null || (new Date(info.startTime).getTime() > new Date(start.createTime).getTime())) {
          info.startTime = start.createTime
        }
      }
      let end = item.statusActionRecord[item.statusActionRecord.length - 1]
      if (end.statusActionKey === 'doing_switch_end') {
        if (info.endTime == null || (new Date(info.endTime).getTime() < new Date(end.createTime).getTime())) {
          info.endTime = end.createTime
        }
      }
    }
    i += 1
  }
  if (!info.isEnd) {
    info.endTime = null
  }
  info.cyOrgNum = cyOrgs.length
  info.cyLeaderNum = cyLeaders.length
  info.fileIds = info.fileIds.join(',').split(',').map(v => Number(v))
  return info
}